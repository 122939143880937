
@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--RS-white-color);
  border-radius: var(--RS-space-48);
  /* box-shadow: inset 0 0 0 2px var(--RS-primary-button-color); */
  background-color: var(--RS-black-color);
  padding: 0 var(--RS-space-16);
  /* height: 48px; */
  -webkit-tap-highlight-color: transparent;
  outline: none;
  font-weight: var(--RS-font-weight-variable-bold);
  position: relative;
  padding: var(--RS-space-16) var(--RS-space-48);
  font-size: var(--RS-font-size-button);

  @media (--md-viewport) {
    width: auto;
    min-width: calc(var(--RS-space-128) + var(--RS-space-32));
    font-size: var(--RS-font-size-button-desktop);
  }
}

.RS-button.RS-button--active,
.RS-button:hover {
  color: var(--RS-white-color);
  background-color: var(--RS-graphite-color);
}


.RS-button--inverted{
  color: var(--RS-black-color);
  background-color: var(--RS-light-grey-color);
}

.RS-button--inverted.RS-button--active,
.RS-button--inverted:hover{
  color: var(--RS-black-color);
  background-color: var(--RS-white-color);
}


.RS-button--small{
  padding: var(--RS-space-12) var(--RS-space-24);
  min-width: unset;
}

.RS-button--ghost{
  background-color: unset;
  color: var(--RS-black-color);
  border: 1px solid var(--RS-dark-grey-color);
}

.RS-button--ghost.RS-button--active,
.RS-button--ghost:hover{
  color: var(--RS-black-color);
  background-color: var(--RS-light-grey-color);
}


/* .RS-button > span {
  position: relative;
  display: inline-block;
  line-height: var(--RS-line-height-md);
  font-size: clamp(1.125rem, 1.1123rem + 0.0633vw, 1.1875rem);
} */

.RS-button > svg.fa-arrow-right {
  margin: 0 0 0 var(--RS-space-16);
  font-size: 24px;
  font-size: clamp(1.25rem, 1.1994rem + 0.2532vw, 1.5rem);
  line-height: 1;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform: translate3d(0, 0, 0);
}


.RS-button.RS-button--nav{
  width: auto;
  max-width: none;
  background: unset;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-bold);
  padding: 0 0 var(--RS-space-4) 0;
  font-size: var(--RS-font-size-mobile-nav);

  @media (--md-viewport) {
    background: var(--RS-black-color);
    color: var(--RS-white-color);
    padding: var(--RS-space-12);
    opacity: 1;
    font-size: var(--RS-font-size-top-nav);
  }
}

.RS-button.RS-button--nav.RS-button--active,
.RS-button.RS-button--nav:hover {
  @media (--md-viewport) {
    color: var(--RS-white-color);
    background-color: var(--RS-graphite-color);
  }
}

.RS-button.RS-button--small{
  font-size: var(--RS-font-size-label);

  @media (--md-viewport) {
    font-size: var(--RS-font-size-label-desktop);
  }
}