/* Top Bar */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-marquee{
  /* width: 100%; */
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  height: var(--RS-space-48);
  /* animation: loopMarquee 6s linear infinite;
  transform: translateX(200%); */

  @media (--lg-viewport) {
    padding: var(--RS-space-64) 0;
  }
}
/* 
.RS-marquee:nth-of-type(2){
  position: absolute;
  left: 50%;
  top: 0;
} */

/* @keyframes loopMarquee {
  100% {
    transform: translate3d(-100%, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  }
} */

.RS-marquee__item {
  /* width: calc(100%/7); */
}


.RS-marquee{
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  /* height: var(--RS-space-48); */
  /* animation: loopMarquee 6s linear infinite; */
  /* transform: translateX(200%); */
  /* padding: var(--RS-space-64) 0;
  position: relative; */
  /* left: calc(100% / 3); */
  /* left: calc(100% / -6); */
  /* left: unset; */
  /* right: calc(100% / -14); */
  /* left: 0; */

  /* @media (--lg-viewport) {
    padding: var(--RS-space-64) 0;
  } */
}



/* @keyframes loopMarquee {
  100% {
    transform: translate3d(-200%, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  }
} */

/* .RS-marquee__item {
  width: calc(100% / 6);
  position: absolute;
} */




.RS-marquee-wrapper{

  padding: var(--RS-space-48) 0;
  width: var(--RS-col-8);
  cursor: pointer;

  @media (--lg-viewport) {
    padding: var(--RS-space-64) 0;
    width: 100%;
  }
}

.RS-marquee__item svg{
  height: var(--RS-space-48);
  margin: 0 auto;
}