/* Intro */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-intro {
  position: relative;
  z-index: 0;
  padding: 12vh var(--RS-space-24) 4vh;
  display: flex;
  align-items: flex-end;
  height: 90vh;

  @media (--h-mobile-viewport) {
    height: auto;
    min-height: 100vh;
  }

  @media (--lg-viewport) {
    height: 100vh;
    min-height: unset;
    padding: 14vh var(--RS-space-32) 8vh;
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-intro__container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  margin: 0 auto;
  height: 100%;

  @media (--lg-viewport) {
    /* flex-direction: row-reverse; */
  }

}

