/* Grid */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-section {
  width: 100%;
  padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-96);


  @media (--md-viewport) {
    padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-128);
  }

  @media (--lg-viewport) {
    padding: var(--RS-space-96) var(--RS-space-32) var(--RS-space-128);
  }
}

.RS-section--inverted{
  background-color: var(--RS-black-color);
  color: var(--RS-white-color);
}

.RS-container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  margin: 0 auto;
  height: 100%;
}

.RS-container.RS-container--narrow {
  max-width: var(--RS-col-4);
}

.RS-content{
  width: 100%;
}

.RS-content--no-overflow{
  overflow: hidden;
}

.RS-content__figure{
  width: 100%;
  height: 100%;
  min-height: 20vh;
  border-radius: var(--RS-space-6);
  background-color: var(--RS-light-grey-color);
}